<template>
    <div class="app" style="overflow:hidden; height: 100%; width: 100%; position: relative" ref="page">

        <!-- Background Image -->
        <img src="img/RockfordMapWallpaper.png" style="z-index: -999; position: absolute; object-fit: cover" class="w-100 h-100"/>

        <!-- Main Page Mobile View -->
<!--        <span v-if="mobile" style="z-index: 999">
            <div class="d-flex flex-column " style="z-index: 999">
                <div class="d-flex justify-content-center align-items-end" style="height: 42.5vh; margin-bottom: 2.5vh; margin: 0 1.5vw 2.5vh 1.5vw">
                    <img :src="logo" style="max-width: 100%; max-height: 320px; background-color: #040707; border: solid white 1.5vw; border-radius: 35px; padding: 15px 20px 15px 20px">
                </div>

                <div class="d-flex justify-content-center" style="height: 52.5vh; margin: 0 1.5vw 2.5vh 1.5vw">
                    <div style="background-color: #040707; border: solid white 1.5vw; width: 500px; height: 200px; padding: 20px; border-radius: 35px"
                         class=" d-flex flex-column align-items-center justify-content-center">
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="text" class="form-control" placeholder="Username"
                                          autocomplete="username email" v-model="input.username"/>
                        </b-input-group>
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input @keyup.enter="login" type="password" class="form-control"
                                          placeholder="Password" autocomplete="current-password"
                                          v-model="input.password"/>
                        </b-input-group>
                        <div class="d-flex justify-content-between w-100">
                            <div style="float:right; margin-bottom: 10px" @click="openPassReset()" class="mouse-pointer d-flex justify-content-end">
                                Forgot my password
                            </div>
                            <b-button variant="dark" class="px-4 d-flex justify-content-center" @click="login()"
                                      style="width: fit-content" :disabled="disabled">Login
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </span>-->

        <!-- Main Page Desktop View -->
        <span style="z-index: 999">
            <div class="d-flex">
                <div style="width: 2%"/>
                <div class="d-flex justify-content-center align-items-center flex-grow-1" style="height: 100vh; width: 100%">
                    <img :src="logo" style="max-width: 100%; max-height: 22vh; background-color: #040707; border: solid white 15px; border-radius: 35px; padding: 35px 50px 35px 50px; margin: 50px">
                </div>
                <div style="width: 2%"/>
                <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
                    <div style="background-color: #040707; border-left: solid white 15px; width: 470px; height: 100vh; padding: 50px"
                         class=" d-flex flex-column align-items-center justify-content-center">
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input type="text" class="form-control" placeholder="Username"
                                          autocomplete="username email" />
                        </b-input-group>
                        <b-input-group style="margin-bottom: 10px">
                            <b-input-group-prepend>
                                <b-input-group-text><i class="icon-lock"/></b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input type="password" class="form-control"
                                          placeholder="Password" autocomplete="current-password"
                                          />
                        </b-input-group>
                        <div class="d-flex justify-content-between w-100">
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import eleQueries from "css-element-queries";
import authStore from "@/store/authStore";

export default {
    name: 'Login_New',
    components: {
        eleQueries
    },
    data() {
        return {
            logo: '/img/mtoplogo.png'
        }
    },
    /*beforeCreate() {
        if (localStorage.mtopToken) {
            let militaryTime = localStorage.getItem("militaryTime");
            localStorage.clear();
            localStorage.setItem("militaryTime", militaryTime);
            location.reload();
        } else {
            localStorage.clear();
        }
    },*/
    mounted() {
        authStore.dispatch("login");
        this.getWindowWidth();
        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth()
        })
        new eleQueries.ResizeSensor(this.$refs["page"], () => {
        })
    },
    methods: {
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
            let height = document.documentElement.clientHeight;
            let width = document.documentElement.clientWidth;
            this.mobile = (width / height) < 1.5;
        },
    }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
div.mouse-pointer {
    cursor: pointer;
}

canvas {
    position: absolute;
    z-index: 1;
}

.container-fluid {
    position: relative;
    z-index: 3;
}
</style>